import { Column, Row } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  max-width: 335px;
  background-color: ${({ theme }) => theme.colors.neutral.dark};
`;

export const FormInnerContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.darker};
    && {
      row-gap: ${theme.spacing.s}px;
    }
  `}
`;

export const FormFooterActionContainer = styled<React.ElementType>(Row)`
  ${({ theme }) => css`
    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        flex-direction: column-reverse;
        align-items: stretch;
        text-align: center;
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        align-items: center;
        column-gap: ${theme.spacing.s}px;
      }
    }
  `}
`;

export const EmailSentPageContainer = styled<React.ElementType>(Column)`
  ${({ theme }) => css`
    align-items: center;
    text-align: center;
    color: ${theme.colors.neutral.darker};

    @container (max-width: ${theme.breakpoints.m}px) {
      && {
        row-gap: ${theme.spacing.m}px;
      }
    }

    @container (min-width: ${theme.breakpoints.m + 1}px) {
      && {
        row-gap: ${theme.spacing.s}px;
      }
    }
  `}
`;
