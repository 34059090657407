import { Button, Column, Text, Title } from "@yolaw/ui-kit-components";
import { useLegalEntity, useSegment } from "hooks";
import { LSCPRoutePath } from "services/router";
import styled, { css } from "styled-components";
import lscpPreview from "./assets/lscp-preview.png";
import lscpPreview2x from "./assets/lscp-preview@2x.png";
import lscpPreview3x from "./assets/lscp-preview@3x.png";
import CompteProTask from "./components/CompteProTask";
import HomePageSection from "./components/HomePageSection";
import { CompteProCompanyStatus, CompteProTaskSlug } from "../../types/compte-pro";
import { getCompteProCompanyStatus } from "./compte-pro-utils";

const StyledSection = styled(HomePageSection)`
  ${({ theme }) => css`
    &.section--compte_pro {
      .btn_compte_pro_access {
        align-self: stretch;
      }

      @container (min-width: ${theme.breakpoints.m}px) {
        .btn_compte_pro_access {
          align-self: center;
          flex-shrink: 0;
        }
      }
    }
  `}
`;

const LSCPBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;

    @container (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      padding: 0 ${theme.spacing.xs}px;

      &.column-gap-l {
        column-gap: ${theme.spacing.l}px;
      }
      &.column-gap-xs {
        column-gap: ${theme.spacing.xs}px;
      }
    }
  `}

  .img-lscp-preview {
    flex-shrink: 0;
    width: 266px;
    height: 122px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: image-set(
      url(${lscpPreview}) 1x,
      url(${lscpPreview2x}) 2x,
      url(${lscpPreview3x}) 3x
    );
  }

  .lscp-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const CompteProSection = () => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  const compteProCompanyStatus = getCompteProCompanyStatus(currentLegalEntity);
  const compteProSubscription =
    currentLegalEntity.lsCompteProAccessibleSubscription!;
  const ssoURL = compteProSubscription.buildSSORedirectionURL(
    LSCPRoutePath.ProAccount
  );
  const isLEActiveAndHasSSOURL =
    compteProCompanyStatus === CompteProCompanyStatus.Active && !!ssoURL;

  const accessLSCP = () => {
    segment.track("ls compte pro cta: clicked", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: compteProSubscription.status,
    });

    window.open(ssoURL!);
  };

  return (
    <StyledSection className="section--compte_pro">
      <Title type="H4">Mon Compte Pro tout-en-un</Title>

      <LSCPBlock
        className={isLEActiveAndHasSSOURL ? "column-gap-xs" : "column-gap-l"}
      >
        <span className="img-lscp-preview" />
        <div className="lscp-info">
          {compteProCompanyStatus === CompteProCompanyStatus.Active && (
            <>
              <Text fontWeightVariant="bold">
                Votre Compte Pro Legalstart est disponible&nbsp;!
              </Text>
              <Text>
                Payez, virez, encaissez : la solution tout-en-un la plus
                efficace pour gérer vos finances.
              </Text>
            </>
          )}
          {compteProCompanyStatus === CompteProCompanyStatus.Inactive && (
            <>
              <Text fontWeightVariant="bold">
                Votre Compte Pro Legalstart est bientôt prêt&nbsp;!
              </Text>
              <Text>
                Nous procéderons à sa création dès que votre société sera
                immatriculée.
              </Text>
            </>
          )}
          {compteProCompanyStatus === CompteProCompanyStatus.Waiting && (
            <>
              <Text fontWeightVariant="bold">
                Votre Compte Pro Legalstart est en cours de création
              </Text>
              <Text>
                Vous pourrez y accéder dès qu'il sera actif. Vous serez notifié
                par email.
              </Text>
            </>
          )}
        </div>
        {isLEActiveAndHasSSOURL && (
          <Button
            className="btn_compte_pro_access"
            size="small"
            onClick={accessLSCP}
          >
            Accéder à mon compte
          </Button>
        )}
      </LSCPBlock>

      {/* Compte Pro Tasks */}
      <Column>
        <CompteProTask slug={CompteProTaskSlug.ActivateCard} />
        <CompteProTask slug={CompteProTaskSlug.SettingInvoices} />
      </Column>
    </StyledSection>
  );
};

export default CompteProSection;
