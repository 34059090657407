/** Based on the LE's SIREN & LE's compte_pro_active */
export enum CompteProCompanyStatus {
  /** LE has SIREN && compte_pro_active */
  Active = "ACTIVE",
  /** LE has SIREN && !compte_pro_active */
  Inactive = "INACTIVE",
  /** LE has NO SIREN && !compte_pro_active */
  Waiting = "WAITING",
}

export enum CompteProTaskSlug {
  ActivateCard = "activate_card",
  SettingInvoices = "setting_invoices",
}

export enum CompteProTaskStatus {
  Done = "done",
  NotDone = "not_done",
}
