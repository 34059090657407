import { Button, Text, Title } from "@yolaw/ui-kit-components";
import { FormalitiesContext } from "contexts";
import FormalitiesSection from "pages/formalities/components/FormalitiesSection";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BuiltRoutePath } from "services/router";
import styled, { css } from "styled-components";
import { FormalityStatus } from "types/formalities";
import HomePageSection from "./components/HomePageSection";

const StyledSection = styled(HomePageSection)`
  ${({ theme }) => css`
    &.section--ongoing_formalities {
      .formality_card_container {
        box-shadow: unset;
        border: 1px solid ${theme.colors.neutral.lightest};
      }

      &.empty_list {
        justify-content: column;
      }
      .btn_formalities_access {
        align-self: stretch;
      }

      @container (min-width: ${theme.breakpoints.m}px) {
        &.empty_list {
          flex-direction: row;
          justify-content: space-between;
        }
        .btn_formalities_access {
          align-self: center;
        }
      }
    }
  `}
`;

const OnGoingFormalitiesSection = () => {
  const navigate = useNavigate();
  const {
    state: { groups },
  } = useContext(FormalitiesContext.Context);

  const inProgressFormalities = groups[FormalityStatus.InProgress].formalities;

  const goToFormalitiesPage = () => {
    navigate(BuiltRoutePath.FormalitiesPage);
  };

  return (
    <StyledSection
      className={`section--ongoing_formalities ${
        inProgressFormalities.length ? "" : "empty_list"
      }`}
    >
      <div>
        <Title type="H4">Mes démarches en cours</Title>
        <Text>Les démarches payées et non finalisées sont affichées ici.</Text>
      </div>

      {/* In-progress Formalities */}
      <FormalitiesSection
        formalities={inProgressFormalities}
        ctaVariant="secondary"
      />

      <Button
        className="btn_formalities_access"
        variant="secondary"
        size="small"
        onClick={goToFormalitiesPage}
      >
        Afficher toutes mes démarches
      </Button>
    </StyledSection>
  );
};

export default OnGoingFormalitiesSection;
