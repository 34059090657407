import { LegalEntity } from "types/legal-entities";
import { CompteProCompanyStatus } from "../../types/compte-pro";

export const getCompteProCompanyStatus = (le: LegalEntity) => {
  if (!!le.siren) {
    if (!!le.compte_pro_active) return CompteProCompanyStatus.Active;
    return CompteProCompanyStatus.Waiting;
  }
  return CompteProCompanyStatus.Inactive;
};
