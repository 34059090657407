import { Tag, Title } from "@yolaw/ui-kit-components";
import { useLegalEntity, useSegment } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import FormalitiesListingPage from "pages/formalities/FormalitiesListingPage";
import { useEffect } from "react";
import styled, { css } from "styled-components";
import CompteProSection from "./CompteProSection";
import OnGoingFormalitiesSection from "./OnGoingFormalitiesSection";

const StyledTag = styled(Tag).attrs({ size: "small" })``;

const StyledPageContainer = styled(PageContainer)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing.m}px;

    .page_header {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.xs}px;
      align-items: flex-start;

      @container (min-width: ${theme.breakpoints.m}px) {
        flex-direction: row;
        ${StyledTag} {
          align-self: center;
        }
      }
    }

    .sections {
      display: flex;
      flex-direction: column;
      row-gap: inherit;

      &.--company-registered {
        flex-direction: column-reverse;
      }
    }
  `}
`;

const HomePageContent = () => {
  const segment = useSegment();
  const currentLegalEntity = useLegalEntity();

  // `x!` because we checked before rendering this component
  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription!;
  const hasCompanyRegistered = !!currentLegalEntity.siren;

  useEffect(() => {
    segment.track("userspace homepage: displayed", {
      le_id: currentLegalEntity.id,
      sub_ls_compte_pro_status: accessibleLSCompteProSub.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPageContainer>
      <div className="page_header">
        <Title type={"H2"}>{currentLegalEntity.name}</Title>
        {hasCompanyRegistered ? (
          <StyledTag color="green">Société immatriculée</StyledTag>
        ) : (
          <StyledTag color="blue">Immatriculation en cours</StyledTag>
        )}
      </div>

      <div
        className={`sections ${
          hasCompanyRegistered ? "--company-registered" : ""
        }`}
      >
        <OnGoingFormalitiesSection />
        <CompteProSection />
      </div>
    </StyledPageContainer>
  );
};

export const HomePage = () => {
  const currentLegalEntity = useLegalEntity();

  const accessibleLSCompteProSub =
    currentLegalEntity.lsCompteProAccessibleSubscription;

  // no LSCP sub > render <FormalitiesPage />
  if (!accessibleLSCompteProSub) {
    return <FormalitiesListingPage />;
  }

  return <HomePageContent />;
};
