import { Title } from "@yolaw/ui-kit-components";
import {
  UniversalAssistance,
  UniversalChat,
  UniversalDocument,
  UniversalFAQ,
  UniversalPhoneConversation,
  UniversalSheets,
} from "@yolaw/ui-kit-icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import { CardsList } from "components/CardsListStyles";
import FeatureCard from "components/FeatureCard";
import { ModalName } from "contexts/app";
import { useApp, useSegment, useUser } from "hooks";
import { PageContainer } from "pages/components/PageStyles";
import { BuiltRoutePath } from "services/router";
import { AJSubscriptionPaymentModal } from "./AJSubscriptionPaymentModal";

const Sections = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.m}px;

    @media (min-width: ${theme.breakpoints.l}px) {
      row-gap: ${theme.spacing.l}px;
    }
  `}
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;

    @media (min-width: ${theme.breakpoints.l}px) {
      row-gap: ${theme.spacing.s}px;
    }
  `}
`;

const StyledFeatureCard = styled(FeatureCard)`
  height: 100%;
`;

const ContactFormalistCard = () => {
  const navigate = useNavigate();

  const onTertiaryButtonClicked = () => {
    navigate(BuiltRoutePath.ContactFormalistPage);
  };

  return (
    <StyledFeatureCard
      icon={<UniversalChat size="32" />}
      title="Contacter mon formaliste"
      description="Le juriste en charge de votre dossier est présent pour vous aider"
      tertiaryButtonOptions={{
        text: "Contacter mon formaliste",
        onClick: onTertiaryButtonClicked,
      }}
    />
  );
};

const ContactTechnicalSupportCard = () => {
  const navigate = useNavigate();

  const onTertiaryButtonClicked = () => {
    navigate(BuiltRoutePath.ContactTechnicalSupportPage);
  };

  return (
    <StyledFeatureCard
      icon={<UniversalAssistance size="32" />}
      title="Contacter le support technique"
      description="Problèmes de paiement, d’abonnement, de connexion,...."
      tertiaryButtonOptions={{
        text: "Contacter le support",
        onClick: onTertiaryButtonClicked,
      }}
    />
  );
};

type AJFeatureCardProps = {
  isLocked: boolean;
};

const ContactLawyerCard = ({ isLocked }: AJFeatureCardProps) => {
  const app = useApp();
  const navigate = useNavigate();

  const openPaymentModal = () => {
    app.openModal({
      name: ModalName.AJSubscriptionPayment,
      openedBy: {
        context: "userspace assistance",
        from: "aj card",
      },
    });
  };

  const onTertiaryButtonClicked = () => {
    if (isLocked) {
      openPaymentModal();
    } else {
      navigate(BuiltRoutePath.ContactLawyerPage);
    }
  };

  return (
    <StyledFeatureCard
      icon={<UniversalPhoneConversation size="32" />}
      title="Contacter un juriste de l'Assistance Legalstart"
      description="On répond à toutes vos questions fiscales et administratives"
      tertiaryButtonOptions={{
        text: "Contacter un juriste",
        icon: isLocked ? "LockFlat" : undefined,
        onClick: onTertiaryButtonClicked,
      }}
    />
  );
};

const DownloadDocumentTemplatesCard = ({ isLocked }: AJFeatureCardProps) => {
  const app = useApp();
  const segment = useSegment();

  const openPaymentModal = () => {
    app.openModal({
      name: ModalName.AJSubscriptionPayment,
      openedBy: {
        context: "userspace assistance",
        from: "document library card",
      },
    });
  };

  const onTertiaryButtonClicked = () => {
    if (isLocked) {
      openPaymentModal();
    } else {
      segment.track("aj template library: displayed");
      window.open(
        "https://www.legalstart.fr/assistance-legalstart/bibliotheque-documents-juridiques/"
      );
    }
  };

  return (
    <StyledFeatureCard
      icon={<UniversalDocument size="32" />}
      title="Télécharger un modèle"
      description="Téléchargez le modèle dont vous avez besoin (contrat, attestation...)"
      tertiaryButtonOptions={{
        text: "Télécharger un modèle de document",
        icon: isLocked ? "LockFlat" : undefined,
        onClick: onTertiaryButtonClicked,
      }}
    />
  );
};

const ConsultFactSheetsCard = () => {
  const segment = useSegment();

  const onTertiaryButtonClicked = () => {
    segment.track("aj practical sheets: displayed");
    window.open("https://www.legalstart.fr/fiches-pratiques/");
  };

  return (
    <StyledFeatureCard
      icon={<UniversalSheets size="32" />}
      title="Consulter nos fiches pratiques"
      description="+ 1 500 fiches pratiques rédigées par Legalstart"
      tertiaryButtonOptions={{
        text: "Consulter les fiches pratiques",
        onClick: onTertiaryButtonClicked,
      }}
    />
  );
};
const AccessTheFAQCard = () => {
  const segment = useSegment();

  const onTertiaryButtonClicked = () => {
    segment.track("aj faq: displayed");
    window.open("https://help.legalstart.fr/fr/");
  };

  return (
    <StyledFeatureCard
      icon={<UniversalFAQ size="32" />}
      title="Accéder à la FAQ"
      description="La FAQ est suffisante dans la majorité des cas"
      tertiaryButtonOptions={{
        text: "Accéder à la FAQ",
        onClick: onTertiaryButtonClicked,
      }}
    />
  );
};

export const LegalstartAssistantPage = () => {
  const segment = useSegment();
  const user = useUser();

  useEffect(() => {
    segment.track("assistance: displayed");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Sections>
        <Section>
          <Title type="h2" text="Questions sur une de mes démarches" />
          <CardsList>
            <li>
              <ContactFormalistCard />
            </li>
          </CardsList>
        </Section>
        <Section>
          <Title type="h2" text="Questions juridiques et fiscales" />
          {/* Tempo stop displaying the upsell banner @see: https://app.clickup.com/t/2571097/TECH-42415?block=block-ca549b65-b081-4457-8c68-6ebff072689f*/}
          {/* {!hasSubscribedAJ && <AssistanceJuridiqueUpsellBanner />} */}
          <CardsList>
            <li>
              <ContactLawyerCard isLocked={!user.hasSubscribedAJ} />
            </li>
            <li>
              <DownloadDocumentTemplatesCard isLocked={!user.hasSubscribedAJ} />
            </li>
            <li>
              <ConsultFactSheetsCard />
            </li>
          </CardsList>
          <AJSubscriptionPaymentModal />
        </Section>
        <Section>
          <Title type="h2" text="Assistance technique" />
          <CardsList>
            <li>
              <ContactTechnicalSupportCard />
            </li>
            <li>
              <AccessTheFAQCard />
            </li>
          </CardsList>
        </Section>
      </Sections>
    </PageContainer>
  );
};
