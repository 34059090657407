import { Column } from "@yolaw/ui-kit-components";
import styled, { css } from "styled-components";

// prettier-ignore
const HomePageSection = styled<React.ElementType>(Column).attrs({as: "section"})`
  ${({ theme }) => css`
    box-shadow: ${theme.shadows.blue.small};
    border-radius: ${theme.borderRadius.s}px;
    padding: ${theme.spacing.xs}px;
    row-gap: ${theme.spacing.s}px;
  `}
`;

export default HomePageSection;
