import { LSPaymentMethod } from "types";
import {
  CapitalDeposit,
  LegalEntity,
  LegalEntityDetails,
} from "types/legal-entities";
import coreApiInstance from "../coreApi";
import { CompteProTaskSlug, CompteProTaskStatus } from "types/compte-pro";

export const getLegalEntities = () =>
  coreApiInstance.get<Array<LegalEntity>>("/my/legal-entities/");

export const getLegalEntityDetails = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.get<LegalEntityDetails>(
    `/my/legal-entities/${legalEntityId}/`
  );

export const getCapitalDeposit = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.get<CapitalDeposit>(
    `/legal-entities/${legalEntityId}/capital-deposit/me/`
  );

/** Update Compte Pro tasks */
export const updateCompteProTask = (
  legalEntityId: LegalEntity["id"],
  taskSlug: CompteProTaskSlug,
  taskStatus: CompteProTaskStatus
) =>
  coreApiInstance.put(
    `/my/legal-entities/${legalEntityId}/update-compte-pro-task/${taskSlug}/`,
    {
      status: taskStatus,
    }
  );

/** Payment Methods */
export const getDefaultPaymentMethods = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.get<LSPaymentMethod.PaymentMethodObject[]>(
    `/my/legal-entities/${legalEntityId}/default-payment-method/`
  );

export const initiateNewPaymentMethod = (
  legalEntityId: LegalEntity["id"],
  stripePaymentMethodId: string
) =>
  coreApiInstance.post<
    | LSPaymentMethod.SetupIntentResponseRequiresAction
    | LSPaymentMethod.SetupIntentResponseSucceed
  >(
    `/my/legal-entities/${legalEntityId}/initiate-new-payment-method/`,
    {
      payment_method_id: stripePaymentMethodId,
    },
    {
      validateStatus: (status) => status < 500,
    }
  );

export const confirmNewPaymentMethod = (
  legalEntityId: LegalEntity["id"],
  setupIntentId: string,
  stripePaymentMethodId: string
) =>
  coreApiInstance.post<LSPaymentMethod.SetupIntentResponseSucceed>(
    `/my/legal-entities/${legalEntityId}/confirm-new-payment-method/`,
    {
      setup_intent_id: setupIntentId,
      payment_method_id: stripePaymentMethodId,
    },
    {
      validateStatus: (status) => status < 500,
    }
  );

export const detachDefaultPaymentMethod = (legalEntityId: LegalEntity["id"]) =>
  coreApiInstance.delete(
    `/my/legal-entities/${legalEntityId}/detach-default-payment-method/`
  );
